/*-------------------------
   Brand
-------------------------*/
.brand-wrap-area {
    padding: 90px 0;
}
.brand-grid {
    padding: 10px 0;
    display: flex;
    justify-content: center;
    a {
        width: 250px;
        display: flex;
        align-items: center;
    }
    img {
        filter: grayscale(100%);
        cursor: pointer;
        margin: 0px auto;
        opacity: 0.3;
        transition: var(--transition);
        max-width: 100%;
    }
    &:hover, &.active {
        img {
            opacity: 1;
            filter: grayscale(0);
        }
    }
}

